.typing-effect{
  margin: 10px 0px;
  height: fit-content;
  display: flex;
  animation: typingEffect 0.5s linear both 0.4s,  blinkingEffect 1s linear 0s infinite;
  overflow:hidden;
  white-space: nowrap;
}

@keyframes typingEffect {
  0%{
    width: 0px;
  }
  100%{
  width: 350px;
  }
}


@keyframes blinkingEffect {
  0%{
    border-right: 5px solid rgba(0, 0, 0, 0.1);
  }
  100%{
    border-right: none

  }
}

@keyframes fadein {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }

}
.fade-in{
  animation: fadein 0.3s linear both 0.4s;
}

.fade-in-delay{
  animation-delay: 1s;
}
.myname {
  margin: 0px;
}


.stroked-text {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}


.set-position{
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}


.project-div{
  display: flex;
  justify-content: space-between;
}

.glass-effect{
  backdrop-filter: blur(5px);
}

@media only screen and (max-width: 1600px){
  .resize-icon{
    display: none;
  }
  html, body, section{
    height: 100%;
    overflow:scroll;
  }
  .portdiv{
    padding: 2rem 0;
  }
  .project-div{
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }
  .project-div > *:first-child{
    margin:0px;
  }
  .project-div > * {
    margin: 50px 0px;

  }
  .dom-ref{
    height: 100%;
  }

  .transform-animate{
    transition: transform 1s ease-in-out;
    visibility: visible;
  } 

.projectIndex{
  width: 60%;
  transform: translateX(-25%);
} 

}


@media only screen and (max-width: 1000px) {
  .portdiv{
    padding: 2rem 0;
  }
  html, body{
    overflow: scroll;
    height: 100%;
  }
  .main-stack{
    display: none;
  }
}

.transition-up{
  animation: transitionup 900ms ease-in-out;
}


@keyframes transitionup {
  0%{
    transform: translateY(300%);
  }100%{
    transform: translateY(0%);
  }
}

@keyframes transitiondown {
  0%{
    transform: translateY(0);
  }100%{
    transform: translateY(300%);
  }
}

@keyframes linkdown {

  0%{
    transform: translateY(-300%);
  }100%{
    transform: translateY(0%);
  }
}

@keyframes linkup {
  0%{
    transform: translateY(0);
  }100%{
    transform: translateY(-300%);
  }
}

.link-up {
  animation: linkup 500ms ease-in-out forwards;
}
.link-down {
  animation: linkdown 400ms ease-in-out forwards;

}
.stack-up{
  animation: transitionup 400ms ease-in-out forwards;
}

.stack-down {
  animation: transitiondown 500ms ease-in-out forwards;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-animation-infinite {
  animation: spin 500ms linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes wave-infinite {
  0%{
    transform: rotate(50deg);
  }
  100%{
    transform: rotate(0deg);

  }
}

.wave-animation-infinite{
  animation: wave-infinite 1s ease-in-out infinite alternate;
  display: inline-block;
}

@keyframes highfive {
  5%{
    transform: scale(0.4);
  }
  30%{
    transform: scale(0.8);
    transform: translateY(2px);
  }
  50%{
    transform: scale(1);
  }
  70%{
    transform: scale(2);
    opacity: .7;
  }
  100%{
    transform: translateY(40px);
    opacity: 0;
  }
}

.highfive-animation{
  animation: highfive 900ms ease-in-out;
  display: inline-block;

}




.svg-name{
  font-weight: 900;
  font-family: Arial;
  font-size: 5rem;


}

.svg-container{
  font-size: 2rem;

  fill: #33664d;
  stroke-width: 3;
  stroke: #2c5c45;
  overflow: visible;
}
.svg-text{
  fill: #33664d;

  animation: animate-stroke 4s infinite alternate;
  font-weight: 900;
}
@keyframes animate-stroke {
  0%{
    fill: transparent;
    stroke: #2c5c45;
    stroke-width: 3;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 32%;
  }
  50%{
    fill: transparent;
    stroke: #2c5c45;
    stroke-width: 3;
  }
  80%, 100%{
    fill: #2c5c45;
    stroke: transparent;
    stroke-dashoffset: -25%;
    stroke-dasharray: 32% 0 ;

  }
}


@keyframes circular-move {
  0%,35%{
    opacity: 0%;


  }

  45%,80%{
    opacity: 1;
  }

  100%{
    transform:   scale(-1, -1) rotate(360deg);
  }
}

g > path{
  opacity: 0;
}

.svg-dot{
  transform: scale(-1, -1);
  animation: circular-move 3s infinite alternate ease-in-out;
  transform-origin: 50%;
}

.svg-dot-2{

  transform:  scale(-1, -1);
  animation: circular-move 3s infinite alternate 1s ease-in-out;
  transform-origin: 50%;

}

.svg-dot-3{
  transform: scale(-1, -1);
  animation: circular-move 3s infinite alternate 1.5s ease-in-out;
  transform-origin: 50%;

}


@keyframes three {
  0%{
    transform: translate3d(0px, 0px, -500px);
  }
  100%{
    transform: translate3d(0px, 0px, 0px);

  }
  
}

.three{
  animation: three 1s linear;
}


ul.tech-stack-list{
  list-style: none;
  display:flex;
  flex-wrap: wrap;
  font-size: 18px;
}

ul.tech-stack-list li::after{
  content:"-";
  margin: 5px;
  
}

ul.tech-stack-list li:nth-last-child(1)::after{
  content:""
}