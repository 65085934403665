.dsa-bar{
    width: 15%;
    padding: 4rem 1rem;
    background-color: rgb(250, 250, 240);
}
.section-bar{
    p {
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 1rem;
        font-weight: 700;
        border-bottom: 1px solid gray;
     }
    li {
        padding-left: 10px;
        font-size: 14px;
        line-height: 30px;
     }
    li:hover{
        background-color: rgba(129, 178, 154, .2);
    }
     .active{
        background-color: rgb(129, 178, 154);
        color:aliceblue;
        font-weight: bolder;
     }
}
